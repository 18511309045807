import { CALL_API } from "../libraries/middleware";
import actionCreator from "../libraries/actionCreator";

interface StoreObject {
  storeId: number;
  storeUuid: string;
  body: object;
}

interface SeatQrcodeObject {
  storeId: number;
  body: object;
}

interface SeatObject {
  storeId: number;
  seatId: number;
  body: object;
}

interface CarouselImageObject {
  storeId: number;
  body: object;
  position: number;
}

interface SecondScreenImageObject {
  storeId: number;
  body: object;
  position: number;
}

interface StoreImageObject {
  storeId: number;
  body: object;
  position: number;
  type: string;
}

interface SystemConfig {
  key: string;
}

interface TagObject {
  businessId: number;
  body: object;
}

interface SeatString {
  storeId: number;
}

interface MiscOptionObject {
  storeId: number;
  miscOptions: object;
}

interface SeatOvertimeMinsObject {
  storeId: number;
  seatOvertimeMins: number;
}

interface StoreEmpAttendanceScheduleVo {
  id: number;
  storeId: number;
  displayName: string;
  endTime: string;
  breakTime: number;
}

interface PartySizeVo {
  size: number;
  min: number;
  max: number;
  plus: number;
  prefix: string;
}

interface QueueingExtraVo {
  enable: boolean;
  cancelable: boolean;
  startTime: string;
  endTime: string;
  maxPartySize: number;
  type: string;
  partySizes: PartySizeVo[];
  takingNoNotification: object;
  queueingNotification: object;
}

interface StoreIntegrationVo {
  id: number;
  storeId: number;
  description: string;
  type: string;
  extra: object;
}

export const { Actions, Constants } = actionCreator({
  getStoresInfo(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/info`,
      },
    };
  },

  updateStoresInfo(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}`,
        body: body,
      },
    };
  },

  updateSimpleStoresInfo(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/simple/${storeId}`,
        body: body,
      },
    };
  },

  updateStoreSeatOrder(formData: SeatObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/reorder-seat`,
        body: body,
      },
    };
  },

  changeHomepageLogo(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/image/welcome`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  deleteHomepageLogo(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/image/welcome`,
      },
    };
  },

  changeBrandImage(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/image/brand`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  deleteBrandImage(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/image/brand`,
      },
    };
  },

  changeInvoiceLogo(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/image/invoice`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  deleteInvoiceLogo(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/image/invoice`,
      },
    };
  },

  changeMealImage(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/image/meal`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  deleteMealImage(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/image/meal`,
      },
    };
  },

  getStoresIntegration(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/integration`,
      },
    };
  },

  addStoresIntegration(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/integration`,
        body: body,
      },
    };
  },

  updateStoresIntegration(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/integration`,
        body: body,
      },
    };
  },

  deleteStoresIntegration(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/integration`,
      },
    };
  },

  addRole(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/userpermission`,
        body: body,
      },
    };
  },

  updateRole(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/userpermission`,
        body: body,
      },
    };
  },

  deleteRole(formData: StoreObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/userpermission`,
        body: body,
      },
    };
  },

  notifyUpdate(formData: StoreObject) {
    const { storeUuid } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/notifyUpdate/${storeUuid}`,
      },
    };
  },

  getSettingFunction() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/permissions`,
      },
    };
  },

  getFoodPreserveTags(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/food-preserve`,
      },
    };
  },

  importFoodPreserveTags(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/food-preserve/import`,
        body: formData,
        apiType: "multipart",
      },
    };
  },

  exportFoodPreserveTags(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/food-preserve/export`,
        apiType: "binary",
      },
    };
  },

  getBusinessDelivery() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/business/delivery/all`,
      },
    };
  },

  getIntegrationTypeList() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/integration`,
      },
    };
  },

  getSeat(formData: SeatObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/seats`,
      },
    };
  },

  getSeatOrder(formData: SeatString) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/seats-order`,
      },
    };
  },

  getChildSeat(formData: SeatObject) {
    const { storeId, seatId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/seat/${seatId}/child-seats`,
      },
    };
  },

  importSeat(formData: SeatObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/import-seats`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  downloadSeat(formData: SeatObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/export-seats`,
        apiType: "binary",
      },
    };
  },

  createSeat(formData: SeatQrcodeObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/seat`,
        body: body,
      },
    };
  },

  updateSeat(formData: SeatObject) {
    const { storeId, seatId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/seat/${seatId}`,
        body: body,
      },
    };
  },

  updateSeatEnable(formData: SeatObject) {
    const { storeId, seatId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/seat/${seatId}/seat-enable`,
        body: body,
      },
    };
  },

  deleteSeat(formData: SeatObject) {
    const { storeId, seatId } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/seat/${seatId}`,
      },
    };
  },

  getCurrentBusiness() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/business/current`,
      },
    };
  },

  getOnlineStore() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/business/online-stores`,
      },
    };
  },

  updateStore(formData: StoreObject) {
    const { storeUuid } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/notifyUpdate/${storeUuid}`,
      },
    };
  },

  getTemplate(formData: SystemConfig) {
    const { key } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/system-config/${key}`,
      },
    };
  },

  getCarouselImage(formData: CarouselImageObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/carousel/image`,
      },
    };
  },

  changeCarouselImage(formData: CarouselImageObject) {
    const { storeId, body, position } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/carousel/image/${position}`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  swapCarouselImage(formData: CarouselImageObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/carousel/position`,
        body: body,
      },
    };
  },

  deleteCarouselImage(formData: CarouselImageObject) {
    const { storeId, position } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/carousel/image/${position}`,
      },
    };
  },

  getSecondScreenImage(formData: SecondScreenImageObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/image/second-screen`,
      },
    };
  },

  changeSecondScreenImage(formData: SecondScreenImageObject) {
    const { storeId, body, position } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/image/second-screen/${position}`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  swapSecondScreenImage(formData: SecondScreenImageObject) {
    const { storeId, body } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/image/second-screen/position`,
        body: body,
      },
    };
  },

  deleteSecondScreenImage(formData: SecondScreenImageObject) {
    const { storeId, position } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/image/second-screen/${position}`,
      },
    };
  },

  getTcpFilterOptions(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/tcp-filter/options`,
      },
    };
  },

  getTcpFilterOptionsSelected(formData: StoreObject) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/tcp-filter/options/selected`,
      },
    };
  },

  getBusinessStores() {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store`,
      },
    };
  },

  getStoreImage(formData: StoreImageObject) {
    const { storeId, type } = formData;
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/store/image/${type}`,
      },
    };
  },

  changeStoreImage(formData: StoreImageObject) {
    const { storeId, body, position, type } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/store/image/${type}/${position}`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  swapStoreImage(formData: StoreImageObject) {
    const { storeId, body, type } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/position/store/image/${type}`,
        body: body,
      },
    };
  },

  deleteStoreImage(formData: StoreImageObject) {
    const { storeId, position, type } = formData;
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/${storeId}/store/image/${type}/${position}`,
      },
    };
  },

  setAssignInfo(storeId: string, invoiceAssignInfo: object) {
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/invoice/assign-info`,
        body: invoiceAssignInfo,
      },
    };
  },

  addStoreInvoice(storeId: string, storeInvoice: object) {
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/invoice/add`,
        body: storeInvoice,
      },
    };
  },

  setSerialNumbers(storeId: string, serialNumbers: object) {
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/${storeId}/serial-numbers`,
        body: serialNumbers,
      },
    };
  },

  sendUpdateStoreWebSocket(store: string) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/notifyUpdate/${store}`,
      },
    };
  },

  sendUpdateMenuWebSocket(store: string) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/menu/notifyUpdate/${store}`,
      },
    };
  },

  getMiscOptions(storeId: number) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/misc-options`,
      },
    };
  },

  updateMiscOptions(formData: MiscOptionObject) {
    const { storeId, miscOptions } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/misc-options`,
        body: miscOptions,
      },
    };
  },

  getDeliverySetting(storeIds: number[] = []) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/getDeliverySetting`,
        query: {
          storeIds,
        },
      },
    };
  },

  updateSeatOvertimeMins(formData: SeatOvertimeMinsObject) {
    const { storeId, seatOvertimeMins } = formData;
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/${storeId}/seat-overtime-mins`,
        body: { seatOvertimeMins: seatOvertimeMins },
      },
    };
  },

  createStoreEmpAttendanceSchedule(formData: StoreEmpAttendanceScheduleVo) {
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store-emp-attendance-schedules`,
        body: formData,
      },
    };
  },

  updateStoreEmpAttendanceSchedule(formData: StoreEmpAttendanceScheduleVo) {
    const { id } = formData;

    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store-emp-attendance-schedules/${id}`,
        body: formData,
      },
    };
  },

  deleteStoreEmpAttendanceSchedule(id: number) {
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store-emp-attendance-schedules/${id}`,
      },
    };
  },

  getInvoiceDonateNum(donateNum: string) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/admin/store/validate/donate?donateNum=${donateNum}`,
      },
    };
  },

  uploadNoResizeImageUrL(body: string) {
    return {
      [CALL_API]: {
        method: "POST",
        path: `/image/upload-original-url`,
        body: body,
        apiType: "multipart",
      },
    };
  },

  getFoodpandaSetting(storeIds: number[] = []) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/platform/settings/FOODPANDA`,
        query: {
          storeIds,
        },
      },
    };
  },

  syncIntegrationMenu(storeId: number, storeIntegrationId: number) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/integration/${storeIntegrationId}/sync-menu`,
      },
    };
  },

  updateStoreUserTerms(formData: StoreObject) {
    const { storeId, body } = formData;
    console.log(body);
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store/set-user-terms/${storeId}`,
        body: body,
      },
    };
  },

  getStoreUserTerms(storeId: string) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/get-user-terms/${storeId}`,
      },
    };
  },

  getQueueingExtraVo(storeId: string) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store-integration/store/${storeId}/queueing`,
      },
    };
  },

  updateQueueingExtraVo(formData: StoreIntegrationVo) {
    const { storeId } = formData;
    return {
      [CALL_API]: {
        method: "PUT",
        path: `/store-integration/store/${storeId}`,
        body: formData,
      },
    };
  },
});
